<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-show="!disabled && Boolean(popupParam.iimAccidentId)" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" />
              <c-btn 
                v-show="!disabled && Boolean(popupParam.iimAccidentId)"
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="accidentInfo"
                :mappingType="completeType"
                label="완료" 
                icon="check"
                @beforeAction="completeAccident"
                @btnCallback="completeCallback" />
              <c-btn
                v-if="!disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="accidentInfo"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveAccident"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text
                required
                :editable="editable"
                :disabled="disabled"
                label="아차사고명"
                name="accidentName"
                v-model="accidentInfo.accidentName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                required
                :editable="editable"
                type="datetime"
                :disabled="disabled"
                :minuteStep="10"
                label="발생가능일시"
                name="occurrenceDate"
                v-model="accidentInfo.occurrenceDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-process
                :required="true"
                :editable="editable"
                :disabled="disabled"
                label="공종"
                name="processCd"
                v-model="accidentInfo.processCd">
              </c-process>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="아차사고종류"
                name="accidentKindCd"
                v-model="accidentInfo.accidentKindCd">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="발생가능장소"
                name="occurrenceLocation"
                v-model="accidentInfo.occurrenceLocation">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :editable="editable"
                :disabled="disabled"
                type="edit"
                codeGroupCd="IIM_GRADE_CD"
                itemText="codeName"
                itemValue="code"
                name="accidentGradeCd"
                label="아차사고등급"
                v-model="accidentInfo.accidentGradeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="disabled"
                :data="accidentInfo"
                label="보고자"
                name="reportUserId"
                v-model="accidentInfo.reportUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :editable="editable"
                :disabled="disabled"
                default="today"
                type="datetime"
                label="보고일시"
                :minuteStep="10"
                name="reportDate"
                v-model="accidentInfo.reportDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant 
                :required="true" 
                :disabled="disabled"
                :editable="editable" 
                type="edit" 
                name="plantCd" 
                v-model="accidentInfo.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="true"
                label="아차사고번호"
                name="accidentNo"
                v-model="accidentInfo.accidentNo">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-radio
                :editable="editable"
                :disabled="disabled"
                :comboItems="weatherItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="weather"
                label="날씨"
                v-model="accidentInfo.weather"
              ></c-radio>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="2"
                :autogrow="false"
                label="긴급조치사항"
                name="emergencyMeasures"
                v-model="accidentInfo.emergencyMeasures">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :isCheck="true"
                :rows="2"
                :autogrow="false"
                label="아차사고사항개요"
                name="overviewAccidentSituation"
                v-model="accidentInfo.overviewAccidentSituation"
                @checkboxClick="checkboxClick">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-card title="사고발생 가능 원인">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="사고발생시 상태"
                    name="occurrenceModeLargeCd"
                    v-model="accidentInfo.occurrenceModeLargeCd">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="발생가능 형태"
                    name="iimNearmissDiscern"
                    v-model="accidentInfo.iimNearmissDiscern">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="직접원인"
                    name="directCause"
                    v-model="accidentInfo.directCause">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="간접원인"
                    name="indirectCause"
                    v-model="accidentInfo.indirectCause">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="기인물"
                    name="openning"
                    v-model="accidentInfo.openning">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :rows="2"
                    label="가해물"
                    name="injurious"
                    v-model="accidentInfo.injurious">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-card title="위험성평가">
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <c-text
                    :editable="editable"
                    :disabled="disabled"
                    label="빈도"
                    type="number"
                    name="frequencyRisk"
                    v-model="accidentInfo.frequencyRisk"
                    @dataChange="dataChange">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <c-text
                    :editable="editable"
                    :disabled="disabled"
                    label="강도"
                    type="number"
                    name="strengthRisk"
                    v-model="accidentInfo.strengthRisk"
                    @dataChange="dataChange">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <c-text
                    :editable="editable"
                    :disabled="disabled"
                    label="위험도"
                    type="number"
                    name="totalRisk"
                    v-model="accidentInfo.totalRisk">
                  </c-text>
                </div>
              </template>
            </c-card>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-card title="원거리 사진" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload-picture
                height="370px"
                :attachInfo="attachInfo1"
                label="원거리"
                :editable="editable&&!disabled">
              </c-upload-picture>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-card title="근거리 사진" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload-picture
                height="370px"
                :attachInfo="attachInfo2"
                label="근거리"
                :editable="editable&&!disabled">
              </c-upload-picture>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'near-register-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
  },
  data() {
    return {
      accidentInfo: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        processCd: '',
        iimNearmissDiscern: null,
        frequencyRisk: 0,
        strengthRisk: 0,
        totalRisk: 0,
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [
        { code: 'WC000001', codeName: '맑음' },
        { code: 'WC000002', codeName: '흐림' },
        { code: 'WC000003', codeName: '비' },
        { code: 'WC000004', codeName: '눈' },
      ],
      humanDamageItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      editable: true,
      searchUrl: '',

      saveUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      completeUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      saveType: 'POST',
      completeType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.accidentInfo.accidentStatusCd) && this.accidentInfo.accidentStatusCd !== 'ISNC000001'
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.near.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.complete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
          this.$set(this.attachInfo1, 'taskKey', this.accidentInfo.iimAccidentId)
          this.$set(this.attachInfo2, 'taskKey', this.accidentInfo.iimAccidentId)
          this.$emit('transInfo', _result.data);
        },);
      } else {
        this.accidentInfo.reportUserId = this.$store.getters.user.userId
      }
    },
    saveAccident() {
      if (this.popupParam.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
        this.accidentInfo.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.accidentStatusCd = 'ISNC000001'
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.iimAccidentId = result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo1, 'taskKey', result.data)
      this.$set(this.attachInfo1, 'isSubmit', uid())
      this.$set(this.attachInfo2, 'taskKey', result.data)
      this.$set(this.attachInfo2, 'isSubmit', uid())
      if (this.saveType === 'POST') {
        this.$emit('changeStatus')
      }
      this.getDetail();
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.accidentInfo.overviewAccidentSituation = 
          '- ' +
          '누가 :            ' + // 누가
          '- ' +
          '누구에게 :         ' + // 누구에게
          '- ' +
          '언제 :             ' + // 언제
          '- ' +
          '어디서 :           ' + // 어디서
          '- ' +
          '무엇을 :           ' + // 무엇을
          '- ' +
          '어떻게 :           ' + // 어떻게
          '- ' +
          '왜 : ';
      } else {
        this.accidentInfo.overviewAccidentSituation = '';
      }
    },
    completeAccident() {
      this.completeType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '등록완료 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISNC000002'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },/* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    dataChange() {
      this.accidentInfo.totalRisk = this.accidentInfo.frequencyRisk * this.accidentInfo.strengthRisk
    },
  }
};
</script>
